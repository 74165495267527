<!-- 企业视频推广 -->
<template>
  <div class="wrap" v-loading="loading" @click="warp_click">
    <div class="add_wrap">
      <div class="help_svg">
        <div class="svg" @click.stop="isContent"><svg-icon iconClass="bangzhu"></svg-icon></div>
        <div class="content" ref="content" :style="{opacity:(isContents?0:1)}" :class="{content_index:isContents}">
          企业发布视频规则： <br>1. 视频长度不能超过30秒 <br>2. 视频标题不能超过20个字符
          <br>3. 每个企业账号只能发布一个视频 <br>4. 视频需要通过审核后才会上架 <br>5. 视频内容需要和选择的职位匹配
        </div>
      </div>
      <el-button
        type="success"
        class="help_btn"
        :disabled="isAdd"
        @click="goAdd"
        >上传视频</el-button
      >
    </div>
    <div class="cont_wrap">
      <div class="list_wrap" v-for="item in videoList" :key="item.id">
        <!-- 视频列表 -->
        <div class="list">
          <div class="upframe" v-if="item.is_hide == 1">
            <img src="~@/assets/icons/xiajia.png" alt="" />
          </div>
          <div class="list_img">
            <img :src="item.video_first_url" alt="" />
          </div>
          <div class="list_nav">
            <div class="play" @click="play(item)">
              <svg-icon iconClass="bofang"></svg-icon>
            </div>
            <div class="gengduo" @click="onmore(item)">
              <svg-icon iconClass="gengduo"></svg-icon>
              <div class="more">
                <div v-if="item.is_hide && item.status == 1" @click="frame('hide', 0, item)">
                  上架
                </div>
                <div v-if="!item.is_hide && item.status == 1" @click="frame('hide', 1, item)">下架</div>
                <div  @click="frame('del', 1, item)">删除</div>
              </div>
            </div>
          </div>
          <!-- 审核 -->
          <div class="upframe" v-if="item.status == 0">
            <img src="~@/assets/icons/shenhezhong.png" alt="" />
          </div>
        </div>
        <div class="list_text" v-if="item.status == 2">
          审核不通过：<br />{{ item.remarks }}
          <el-button @click="delVideo(item)" class="del" type="danger" plain
            >删除</el-button
          >
        </div>
        <!-- 视频标题等 -->
        <div class="list_tips">
          <div class="title">
            {{ item.video_title }}
          </div>
          <div class="tips">
            <div class="img">
              <img :src="item.company_logo" alt="" />
            </div>
            <div class="text">
              {{ item.company_name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="noVideo" v-show="!isAdd">暂无视频</div>

    <div class="video_wrap" v-show="isVideo" @click="videoClose">
      <div class="vd_wrap">
        <div class="video">
          <video
            @click.stop
            ref="video"
            :src="video_url"
            controls="controls"
          ></video>
        </div>
        <div class="video_title">
          {{ this.video_title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "axios";
import {
  getVideolist,
  getVideodetail,
  videoUpdate,
} from "@/api/enterpriseVideo/enterpriseVideo";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      isVideo: false,
      videoList: [],
      video_url: "",
      video_title: "",
      a: [],
      isAdd: true,
      isContents:true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    warp_click(){
      if(!this.isContents){
        this.isContents = true
      }
    },
    // 点击视频规则显示隐藏
    isContent(){
      this.isContents = !this.isContents
    },
    
    // 更多点击
    onmore(item) {
      // console.log(item);
      // if (item.status == 0) {
      //   this.$message.warning("正在审核中......");
      // }
    },
    // 前往上传视频
    goAdd() {
      this.$router.push({
        path: "/addVideo",
      });
    },
    // 获取视频列表
    getList() {
      this.loading = true;
      getVideolist().then((res) => {
        if (res.code == 0) {
          this.videoList = res.data.video_list;
          if (this.videoList.length != 0) {
            this.isAdd = true;
          } else {
            this.isAdd = false;
          }
        } else {
          console.log(res.msg);
        }
      });
      this.loading = false;
    },
    // 视频播放
    play(item) {
      this.loading = true;
      getVideodetail({ video_id: item.id }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.video_url = res.data.video_url;
          this.video_title = res.data.video_title;
          this.isVideo = true;
          var video = this.$refs.video;
          setTimeout(function () {
            video.play();
          }, 2000);
        } else {
          console.log(res.msg);
        }
      });
      this.loading = false;
      // console.log(this.$refs.video)
    },
    // 视频关闭
    videoClose() {
      this.isVideo = false;
      this.$refs.video.pause();
    },
    // 视频上下架删除
    //
    frame(update, num, item) {
      if (update == "del") {
        // 删除
        this.$confirm("是否删除该视频？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          videoUpdate({
            video_id: item.id,
            update_data: { is_del: 1 },
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "视频删除成功!",
              });
              this.getList();
            }
          });
        });
      } else if (update == "hide") {
        // 上下架
        if (num == 1) {
          this.$confirm("是否选择下架视频？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            videoUpdate({
              video_id: item.id,
              update_data: { is_hide: 1 },
            }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "视频已下架",
                });
                this.getList();
              }
            });
          });
        } else if (num == 0) {
          this.$confirm("是否选择上架视频？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            console.log(item.id);
            videoUpdate({
              video_id: item.id,
              update_data: { is_hide: 0 },
            }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "视频已上架",
                });
                this.getList();
              }
            });
          });
        }
      }
    },
    // 审核不成功删除
    delVideo(item) {
      videoUpdate({
        video_id: item.id,
        update_data: { is_del: 1 },
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "视频删除成功,请重新上传!",
          });
          this.getList();
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  // margin-top: 80px;
  box-sizing: border-box;
  min-height: 80vh;
  margin: 0 25px;
}
.add_wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.help_btn {
  cursor: pointer;
}
.add {
  color: #fff;
  background-color: #67c23a;
  width: 98px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
}
.cont_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.list_wrap {
  position: relative;
  margin: 0 auto 50px;
  // margin: 0 87.5px 50px;
}
.list {
  width: 350px;
  height: 504px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.list_text {
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 504px;
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px;
}
.upframe {
  position: absolute;
  top: 0;
  left: 0;
}
.upframe img {
  width: 100px;
  height: 100px;
}
.list:hover .list_nav {
  height: 40px;
}

.list:hover .gengduo svg,
.list:hover .play svg {
  height: 30px;
}

.list_nav {
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  box-sizing: border-box;
}
.play svg {
  transition: all 0.3s;
  width: 30px;
  height: 0;
}
.gengduo {
  position: relative;
}
.gengduo:hover .more div {
  width: 200px;
  height: 50px;
}
.gengduo .more {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.gengduo .more div {
  transition: all 0.3s;
  box-sizing: border-box;
  width: 0;
  padding-left: 50px;
  line-height: 48px;
  height: 0;
}
.gengduo .more div:hover {
  background-color: #e0e0e0;
}
.gengduo svg {
  transition: all 0.3s;
  width: 30px;
  height: 0;
}
.list_img {
  width: 100%;
  height: 100%;
}
.list_img img {
  width: 100%;
  height: 100%;
}

.video_wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2999;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vd_wrap {
  width: 20vw;
  height: auto;
}

.del {
  position: absolute;
  right: 10px;
  top: 10px;
}
.list_tips {
  width: 350px;
}
.list_tips .title {
  font-size: 20px;
  margin: 10px 20px;
  box-sizing: border-box;
}
.list_tips .tips .text {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list_tips .tips {
  display: flex;
  margin: 0 20px;
  width: 100%;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.list_tips .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.list_tips .img img {
  width: 100%;
  height: 100%;
}
.video {
  height: auto;
  width: 100%;
}
.video video {
  width: 100%;
  height: auto;
}
.video_title {
  font-size: 24px;
  color: #fff;
  margin-top: 10px;
}
.noVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  color: #aaa;
}

.help_svg {
  margin-right: 20px;
  display: flex;
  align-items: center;
    position: relative;
}
.help_svg svg{
  cursor: pointer;
}
.help_svg .content {
  position: absolute;
  top: 40px;
  z-index: 999;
  transition: all .3s;

  opacity: 0;
  background: #fff;
  min-width: 225px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 #948c8c;
  word-break: break-all;
}
.content_index{
  z-index: 0 !important;
}
</style>
