import {
    require
} from "./../../utils/http"

// 获取视频列表
function getVideolist(data) {
    return require('post', '/api/hr24_ee/share/v1/video_list/', data)
}
// 获取视频详情
function getVideodetail(data) {
    return require('post', '/api/hr24_ee/share/v1/video_detail/', data)
}
// 视频状态修改
function videoUpdate(data) {
    return require('post', '/api/hr24_ee/share/v1/video_update/', data)
}
//图片上传接口
function files_upload(data) {
    return require('post', '/api/hr24_ee/user/v1/files_upload/', data, false)
}
// 企业上传视频
function uploadingvideo(data) {
    return require('post', '/api/hr24_ee/share/v1/uploading_video/', data, )
}
// 公司职位展示
function getCompanyNewShow(data) {
    return require('post', '/api/hr24_ee/company/v1/company_info/', data)
}
//  获取视频宽高时长
function getVideoInfo(data) {
    return require('post', '/api/hr24_ee/share/v1/get_video_info/', data)
}
//  图片上传base64
function filesUploadBase(data) {
    return require('post', '/api/hr24_ee/user/v1/files_upload_base/', data)
}
export {
    getVideolist,
    getVideodetail,
    videoUpdate,
    uploadingvideo,
    getCompanyNewShow,
    getVideoInfo,
    files_upload,
    filesUploadBase
}